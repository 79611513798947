import React, { FC } from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"

const SprucePage: FC = () => (
  <Layout>
    <SEO title="Síða tvö" />
    <h3>
      Sitkagreni (<i>Picea sitchensis</i> (Bong.)Carr.) og sitkabastarður (<i>Picea x
      lutzii</i> Little)
    </h3>
    <p>
      Hér eru teknar saman tvær náskyldar tegundir, sitkagreni og sitkabastarður
      sem er náttúrulegur blendingur sitkagrenis og hvítgrenis (<i>Picea glauca</i>
      (Moench) Voss.). Oft er erfitt að sjá muninn á þessum tegundum og sum
      kvæmi sitkagrenis eru með væga blöndun af hvítgreni. Þessar tegundir sýna
      svipað vaxtarmunstur og vaxtarþrótt. Þó er vaxtartími sitkabastarðs að
      jafnaði styttri en sitkagrenis og sitkabastarður hentar betur þar sem
      sumarið er stutt og meiri hætta á frostum síðla vors og snemma haust s.s.
      eins og á Norðurlandi.
    </p>
    <p>
      Verða stórvaxnastir barrviða á Íslandi. Æskuvöxtur er oft hægur á bersvæði
      og smáplöntur viðkvæmar fyrir veðurfarsskemmdum. Afföll eru því oft
      töluverð við ræktun á bersvæði.
    </p>
    <p>
      Geta vaxið við hafrænt loftslag en þola illa svæði þar sem hætta er á
      næturfrostum s.s. sléttur og dalbotna og er hreint sitkagreni viðkvæmara
      en sitkabastarður hvað það varðar. Bestu svæðin á Íslandi eru:
      Fljótsdalshérað innanvert, innsveitir Suðausturlands,
      Suðurlandsundirlendis og Borgarfjarðar ásamt miðhluta Eyjafjarðar og
      S.-Þingeyjarsýslu en þá að frádregnum dalbotnum og sléttum þar sem hætta
      er á frosti yfir vaxtartímann.
    </p>
    <p>
      Vaxa best á frjósömu landi, þá bæði framræstu, hallandi landi og
      valllendi. Geta líka vaxið í miðlungsfrjóu landi en rýrt og lítt gróið
      land hentar ekki. Þó eru eins og í tilviki alaskaaspar til undantekningar
      en sitkagreni og sitkabastarður teljast þó henta enn síður en alaskaösp
      til landgræðslu.
    </p>
    <p>
      Fjölga sér náttúrulega með fræplöntum. Fræ berst stutt frá vaxtarstað
      (þungt fræ).
    </p>
    <p>
      Hæsta hlutfall borðviðarnýtingar íslenskra trjátegunda. Það sama gildir um
      iðnviðarnýtingu.
    </p>
  </Layout>
)

export default SprucePage
